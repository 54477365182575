import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Banner } from "../components";
import { push } from "connected-react-router";
import { store } from "../utilities";
import {ReactComponent as Logo} from '../travel_shoppr_logo.svg';

class Header extends React.Component {
  render() {
    const currencySelector = (
      <Dropdown
        type="currency"
        selectedItem={this.props.currency}
        items={this.props.currencies}
        onItemSelect={this.props.onCurrencyChanged}
      />
    );
    var dropdowns = (
      <div>
        <div
          className={`px-4 mb-4 xl:grid xl:grid-cols-2 xl:items-center xl:gap-x-8`}
        >
          <div className="grid grid-cols-2 xl:grid-cols-1 items-center">
            <p className="text-primary text-lg inline-block mr-2">
              From:
            </p>
            <Dropdown
              type="source"
              selectedItem={this.props.source}
              removeItems={[this.props.destination]}
              items={this.props.countries}
              onItemSelect={this.props.onSourceChanged}
            />
          </div>
          <div className="grid grid-cols-2 xl:grid-cols-1 mt-2 xl:mt-0 items-center">
            <p className="text-primary text-lg inline-block mr-2">
            Shopping in:
            </p>
            <Dropdown
              type="destination"
              selectedItem={this.props.destination}
              removeItems={[this.props.source]}
              items={this.props.countries}
              onItemSelect={this.props.onDestinationChanged}
            />
          </div>
        </div>
      </div>
    );
    return (
      <header className="page-header">
        <nav className="lg:border-b border-purple-200 border-dashed">
          <div className="px-4 py-8 flex items-center justify-between">
            <a
              title="Home"
              onClick={() => store.dispatch(push("/"))}
            >
              <Logo />
            </a>
            <div className="block">
              {!this.props.countries.length > 0 ? "" : currencySelector}
            </div>
          </div>
        </nav>
        <Banner title={this.props.title} subtitle={this.props.subtitle} />
        {!this.props.countries.length > 0 ? "" : dropdowns}
      </header>
    );
  }
}

Header.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  source: PropTypes.string,
  destination: PropTypes.string,
  currency: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onSourceChanged: PropTypes.func,
  onDestinationChanged: PropTypes.func,
  onCurrencyChanged: PropTypes.func,
};

export default Header;
