import React from "react";
import PropTypes from "prop-types";
import { SearchBox, ProductCard, OptionPicker } from "../components";
import { Header } from "../containers";
import {
  convertToQueryParameterString,
  getCurrentQueryParameters,
  fetchMetadata,
  baseUrl,
  browserHistory,
  store,
} from "../utilities";
import Loading from "../components/Loading";

function BrandPage() {
  var urlParameters = getCurrentQueryParameters();
  var [source, setSource] = React.useState(urlParameters.get("source") || "");
  var [destination, setDestination] = React.useState(
    urlParameters.get("destination") || ""
  );
  var [currency, setCurrency] = React.useState(
    urlParameters.get("currency") || ""
  );
  var [sortOrder, setSortOrder] = React.useState(
    urlParameters.get("sort_order") || "asc"
  );
  var [sortBy, setSortBy] = React.useState(
    urlParameters.get("sort_by") || "price_difference"
  );
  var [countries, setCountries] = React.useState([]);
  var [currencies, setCurrencies] = React.useState([]);
  var [brandData, setBrandData] = React.useState(null);
  var [productSearchText, setProductSearchText] = React.useState("");
  var [fetchMeta, setFetchMeta] = React.useState(true);
  var [fetchProducts, setFetchProducts] = React.useState(false);
  var [pageNumber, setPageNumber] = React.useState(1);
  var [products, setProducts] = React.useState([]);
  var [totalPages, setTotalPages] = React.useState(1);

  var [pageLoadingStatus, setPageLoadingStatus] = React.useState(true);

  var brandId = parseInt(urlParameters.get("brandId"));
  var title = brandData ? brandData.name : "";
  var subtitle = brandData
    ? `On average, products from ${brandData.name} are ${Math.abs(
        brandData.cost_difference
      )}% ${brandData.cost_difference > 0 ? "higher" : "lower"} in ${
        brandData.destination.name
      } compared to ${brandData.source.name}`
    : "";

  React.useEffect(() => {
    if (fetchProducts && source && destination && currency) {
      store.dispatch({ type: "SET_SOURCE", value: source });
      store.dispatch({ type: "SET_DESTINATION", value: destination });
      store.dispatch({ type: "SET_CURRENCY", value: currency });
      var queryParameters = {
        brandId: brandId,
        source: source,
        destination: destination,
        currency: currency,
        sort_order: sortOrder,
        sort_by: sortBy,
      };
      browserHistory.push({
        search: "?" + new URLSearchParams(queryParameters).toString(),
      });
      queryParameters["q"] = productSearchText;
      queryParameters["page_number"] = pageNumber;
      delete queryParameters["brand_id"];
      setFetchProducts(false);
      var queryParamString = convertToQueryParameterString(queryParameters);
      fetch(`${baseUrl()}v1/brand/${brandId}/products?${queryParamString}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            var allProducts;
            if (result.page_number > 1) {
              allProducts = products.concat(result.products);
            } else {
              allProducts = result.products;
            }
            setProducts(allProducts);
            setPageNumber(result.page_number);
            setTotalPages(result.total_pages);
            setPageLoadingStatus(false);
          },
          (error) => {
            alert("Something went wrong while fetching products");
          }
        );
    }
  }, [
    source,
    destination,
    currency,
    productSearchText,
    fetchProducts,
    products,
    brandId,
    pageNumber,
    sortOrder,
    sortBy,
  ]);

  React.useEffect(() => {
    if (
      brandData &&
      brandData.brand_id === brandId &&
      brandData.source.code === source &&
      brandData.destination.code === destination
    ) {
      return;
    }
    var queryParamString = convertToQueryParameterString({
      source: source,
      destination: destination,
      currency: currency,
    });
    fetch(`${baseUrl()}v1/brand/${brandId}?${queryParamString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setBrandData(result);
        },
        (error) => {
          alert("Something went wrong. Please reload the page and try again!");
        }
      );
  }, [source, destination, brandId, brandData, currency]);

  var increasePageNumber = () => {
    setPageNumber(pageNumber + 1);
    setFetchProducts(true);
  };

  React.useEffect(() => {
    if (fetchMeta) {
      setFetchMeta(false);
      fetchMetadata(
        source,
        destination,
        currency,
        (result) => {
          setSource(result.source);
          setDestination(result.destination);
          setCurrency(result.currency);
          setCountries(result.countries);
          setCurrencies(result.currencies);
          setFetchProducts(true);
        },
        (error) => {
          alert("Something went wrong. Please reload the page and try again!");
          setFetchMeta(true);
        }
      );
    }
  }, [currency, destination, fetchMeta, source]);

  var wrappedSetterMethod = (setterMethod) => {
    return function (value) {
      setterMethod(value);
      setFetchProducts(true);
    };
  };

  var onSortOptionSelect = (sortOption) => {
    var [sortBy, sortOrder] = sortOption.split("____");
    setSortBy(sortBy);
    setSortOrder(sortOrder);
    setFetchProducts(true);
  };

  return (
    <div>
      <Header
        source={source}
        destination={destination}
        currency={currency}
        countries={countries}
        currencies={currencies}
        title={title}
        subtitle={subtitle}
        onSourceChanged={wrappedSetterMethod(setSource)}
        onDestinationChanged={wrappedSetterMethod(setDestination)}
        onCurrencyChanged={wrappedSetterMethod(setCurrency)}
      />
      <div className="full-width">
        <SearchBox
          value={productSearchText}
          hint="Enter product names to search..."
          onTextChange={wrappedSetterMethod(setProductSearchText)}
        />
        <OptionPicker
          options={optionPickerConfig(source, destination, countries)}
          selected={`${sortBy}____${sortOrder}`}
          title="Sort"
          onSelect={onSortOptionSelect}
        />
        {pageLoadingStatus && <Loading />}
        <div
          id="product-container"
          className="brand-cards-container p-4 grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 container mx-auto"
        >
          {products.map((product) => {
            return (
              <ProductCard
                key={product.product_id}
                id={product.product_id}
                image={product.image}
                percentageDiff={Math.round(product.cost_difference)}
                name={product.description}
                sourcePrice={Math.round(product.converted_source_price)}
                destinationPrice={Math.round(
                  product.converted_destination_price
                )}
                currency={product.currency}
                sourceUrl={product.source_url}
                destinationUrl={product.destination_url}
                destination={destination}
              />
            );
          })}
        </div>
        <div className="text-center">
          <button
            id="load-more"
            style={{
              visibility: totalPages > pageNumber ? "visible" : "hidden",
            }}
            type="button"
            onClick={increasePageNumber}
            className="full-width-cta bg-gray-800 text-white font-medium py-2 px-4 mt-4 rounded"
          >
            Load more
          </button>
        </div>
      </div>
    </div>
  );
}

function optionPickerConfig(source, destination, countries) {
  var sourceName, destinationName;
  countries.forEach((element) => {
    if (element.value == source) {
      sourceName = element.name;
    } else if (element.value == destination) {
      destinationName = element.name;
    }
  });
  return [
    {
      value: "price_difference____asc",
      name: `Cheaper in ${destinationName}`,
    },
    {
      value: "price_difference____desc",
      name: `Cheaper in ${sourceName}`,
    },
    {
      value: "source_price____asc",
      name: `Price in ${sourceName} (Asc)`,
    },
    {
      value: "source_price____desc",
      name: `Price in ${sourceName} (Desc)`,
    },
    {
      value: "destination_price____asc",
      name: `Price in ${destinationName} (Asc)`,
    },
    {
      value: "destination_price____desc",
      name: `Price in ${destinationName} (Desc)`,
    },
  ];
}

BrandPage.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  source: PropTypes.string,
  destination: PropTypes.string,
  currency: PropTypes.string,
  brandId: PropTypes.number,
};

export default BrandPage;
