import React from "react";
import PropTypes from "prop-types";
import { ProductCard, BrowseBrandCard } from "../components";

class BrandRow extends React.Component {
  render() {
    return (
      <div className="bg-white mx-4 my-4 rounded-xl shadow-sm">
      <div className="max-w-6xl mx-auto py-8 px-4 sm:py-10 lg:max-w-7xl lg:px-6">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">{this.props.brandName}</h2>
          <a href="#" className="hidden text-sm font-medium text-purple-600 hover:text-purple-500 md:block">
            View collection<span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
        {this.props.products.slice(0, 4).map((product) => {
            return (
              <ProductCard
                key={product.product_id}
                id={product.product_id}
                image={product.image}
                percentageDiff={Math.round(product.cost_difference)}
                name={product.description}
                sourcePrice={Math.round(product.converted_source_price)}
                destinationPrice={Math.round(
                  product.converted_destination_price
                )}
                currency={product.currency}
                sourceUrl={product.source_url}
                destinationUrl={product.destination_url}
                destination={this.props.destination}
                source={this.props.source}
                onCardClick={() => this.props.onBrandClick(this.props.brandId)}
              />
            );
          })}
        </div>

        <div className="mt-8 text-sm md:hidden" onCardClick={this.props.onBrandClick} id={this.props.brandId} name={this.props.brandName}>
          <p className="font-medium text-purple-600 hover:text-purple-500">
            View collection<span aria-hidden="true" > &rarr;</span>
          </p>
        </div>
      </div>
    </div>
      // <div className="container px-4 mx-auto">
      //   <h1 className="row-title text-xl md:text-2xl font-extrabold mb-2 mt-8">
      //     {this.props.brandName}
      //   </h1>
      //   <div className="product-row grid grid-cols-2 gap-4 sm:grid-cols-4">
      //     {this.props.products.slice(0, 3).map((product) => {
      //       return (
      //         <ProductCard
      //           key={product.product_id}
      //           id={product.product_id}
      //           image={product.image}
      //           percentageDiff={Math.round(product.cost_difference)}
      //           name={product.description}
      //           sourcePrice={Math.round(product.converted_source_price)}
      //           destinationPrice={Math.round(
      //             product.converted_destination_price
      //           )}
      //           currency={product.currency}
      //           sourceUrl={product.source_url}
      //           destinationUrl={product.destination_url}
      //           destination={this.props.destination}
      //           onCardClick={() => this.props.onBrandClick(this.props.brandId)}
      //         />
      //       );
      //     })}
      //     <BrowseBrandCard
      //       id={this.props.brandId}
      //       name={this.props.brandName}
      //       onCardClick={this.props.onBrandClick}
      //     />
      //   </div>
      // </div>
    );
  }
}

BrandRow.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.exact({
      product_id: PropTypes.number,
      image: PropTypes.string,
      cost_difference: PropTypes.string,
      description: PropTypes.string,
      converted_source_price: PropTypes.string,
      converted_destination_price: PropTypes.string,
      currency: PropTypes.string,
      source_url: PropTypes.string,
      destination_url: PropTypes.string,
    })
  ),
  brandId: PropTypes.number,
  brandName: PropTypes.string,
  onBrandClick: PropTypes.func,
  destination: PropTypes.string,
  source: PropTypes.string
};

export default BrandRow;
