import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'

export const browserHistory = createBrowserHistory()

export const store = createStore(
  createRootReducer(browserHistory), // root reducer with router state
  {},
  compose(
    applyMiddleware(
      routerMiddleware(browserHistory), // for dispatching history actions
      // ... other middlewares ...
    ),
  ),
);