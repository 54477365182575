import React from "react";
import PropTypes from "prop-types";

class Banner extends React.Component {
  render() {
    return (
      <div className="relative">
        <div className="mt-10 mb-10 mx-auto max-w-screen-xl px-4 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28 relative z-index-10">
          <div>
            <h3 className="text-4xl tracking-tight leading-10 font-bold text-primary sm:text-5xl sm:leading-none md:text-6xl">
              {this.props.title}
            </h3>
            <p className="sm:text-2xl mt-3 text-base text-primary text-opacity-75 md:mt-5">
              {this.props.subtitle}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Banner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Banner;
