import React from "react";
import PropTypes from "prop-types";
import { SearchBox } from "../components";
import { Header, BrandRow } from "../containers";
import {
  convertToQueryParameterString,
  getCurrentQueryParameters,
  fetchMetadata,
  baseUrl,
} from "../utilities";
import { browserHistory, store } from "../utilities";
import { push } from "connected-react-router";
import Loading from "../components/Loading";

function Dashboard() {
  var state = store.getState();
  var urlParameters = getCurrentQueryParameters();
  var [source, setSource] = React.useState(
    urlParameters.get("source") || state.metadata.source || ""
  );
  var [destination, setDestination] = React.useState(
    urlParameters.get("destination") || state.metadata.destination || ""
  );
  var [currency, setCurrency] = React.useState(
    urlParameters.get("currency") || state.metadata.currency || ""
  );
  var [brands, setBrands] = React.useState([]);
  var [brandSearchText, setBrandSearchText] = React.useState("");
  var [countries, setCountries] = React.useState(
    [source, destination].filter((x) => x)
  );
  var [currencies, setCurrencies] = React.useState([currency].filter((x) => x));
  var [fetchMeta, setFetchMeta] = React.useState(true);
  var [fetchBrands, setFetchBrands] = React.useState(false);
  var [brandId, setBrandId] = React.useState(null);
  var [redirect, setRedirect] = React.useState(false);

  var [pageLoadingStatus, setPageLoadingStatus] = React.useState(true);

  var wrappedSetterMethod = (setterMethod) => {
    return function (value) {
      setterMethod(value);
      setFetchBrands(true);
    };
  };

  var wrappedBrandIdSetterMethod = (brandId) => {
    setBrandId(brandId);
    setRedirect(true);
  };

  React.useEffect(() => {
    if (redirect) {
      store.dispatch(
        push(
          `/brand?brandId=${brandId}&source=${source}&destination=${destination}&currency=${currency}`
        )
      );
    }
  }, [brandId, currency, destination, redirect, source]);

  React.useEffect(() => {
    if (!(fetchBrands && source && destination && currency)) {
      return;
    }
    var queryParameters = {
      source: source,
      destination: destination,
      currency: currency,
    };
    browserHistory.push({
      search: "?" + new URLSearchParams(queryParameters).toString(),
    });
    store.dispatch({ type: "SET_SOURCE", value: source });
    store.dispatch({ type: "SET_DESTINATION", value: destination });
    store.dispatch({ type: "SET_CURRENCY", value: currency });
    queryParameters["q"] = brandSearchText;
    var queryParamString = convertToQueryParameterString(queryParameters);
    fetch(`${baseUrl()}v1/brands?${queryParamString}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setBrands(result.brands);
          setFetchBrands(false);
          setPageLoadingStatus(false);
        },
        (error) => {
          console.log("Brand fetch error");
          console.log(error);
          alert("Something went wrong. Please reload the page and try again!");
        }
      );
  }, [fetchBrands, source, destination, currency, brandSearchText]);

  React.useEffect(() => {
    if (fetchMeta) {
      setFetchMeta(false);
      fetchMetadata(
        source,
        destination,
        currency,
        (result) => {
          setSource(result.source);
          setDestination(result.destination);
          setCurrency(result.currency);
          setCountries(result.countries);
          setCurrencies(result.currencies);
          setFetchBrands(true);
        },
        (error) => {
          alert("Something went wrong. Please reload the page and try again!");
          setFetchMeta(true);
        }
      );
    }
  }, [fetchMeta, source, destination, currency]);

  return (
    <div>
      <Header
        source={source}
        destination={destination}
        currency={currency}
        countries={countries}
        currencies={currencies}
        title="Buy your favorite brand at the best price, across the world."
        subtitle="Compare prices of over 2,000,000 products across different countries."
        onSourceChanged={wrappedSetterMethod(setSource)}
        onDestinationChanged={wrappedSetterMethod(setDestination)}
        onCurrencyChanged={wrappedSetterMethod(setCurrency)}
      />
      <div className="container container-margin full-width mx-auto">
        <SearchBox
          value={brandSearchText}
          hint="Filter by brand name.."
          onTextChange={wrappedSetterMethod(setBrandSearchText)}
        />
        {pageLoadingStatus && <Loading />}
        <div>
          {brands.map((brand) => {
            return (
              <BrandRow
                key={brand.brand_id}
                products={brand.products}
                brandId={brand.brand_id}
                brandName={brand.name}
                onBrandClick={wrappedBrandIdSetterMethod}
                destination={destination}
                source = {source}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  currencies: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  source: PropTypes.string,
  destination: PropTypes.string,
  currency: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Dashboard;
