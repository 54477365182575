function metadata(state = {
        source: null,
        destination: null,
        currency: null
    }, action) {
    switch (action.type) {
        case 'SET_SOURCE':
            return {
                ...state,
                source: action.value
            }
        case 'SET_DESTINATION':
            return {
                ...state,
                destination: action.value
            }
        case 'SET_CURRENCY':
            return {
                ...state,
                currency: action.value
            }
        default:
            return state;
    }
}

export default metadata