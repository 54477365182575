import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import getSymbolFromCurrency from "currency-symbol-map";
import ReactCountryFlag from "react-country-flag";

class ProductCard extends React.Component {
  render() {
    var suggestionText;
    var costTypeClass;

    if (this.props.percentageDiff < 0) {
      suggestionText = `${this.props.percentageDiff}% cheaper in ${this.props.destination}`.replace('-', '')
      costTypeClass ="text-green-800"
    }
    else if (this.props.percentageDiff > 0) {
      suggestionText = `${this.props.percentageDiff}% costlier ${this.props.destination}`
      costTypeClass ="text-red-800"
    }
    else if (this.props.percentageDiff === 0) { suggestionText = "No difference"; costTypeClass ="text-gray-500"}
    var onCardClick = () => {
      this.props.onCardClick ?
        this.props.onCardClick() :
        window.open(
          this.props.percentageDiff > 0
            ? this.props.sourceUrl
            : this.props.destinationUrl,
          "_blank"
        );
    };
    return (
      <div className="group relative" onClick={onCardClick}>
      <div className="w-full h-56 bg-gray-200 rounded-md overflow-hidden group-hover:opacity-75 lg:h-72 xl:h-80">
        <img
          src={this.props.image}
          alt={this.props.name}
          className="w-full h-full object-center object-cover"
        />
      </div>
      <h3 className="mt-4 text-sm text-gray-700">
        <a href="{product.href}">
          <span className="absolute inset-0" />
          {this.props.name}
        </a>
      </h3>
      <p className="mt-1 text-sm text-gray-500">{this.props.source} Price — {getSymbolFromCurrency(this.props.currency)} {this.props.sourcePrice}</p>
      <p className="mt-1 text-sm font-semibold text-gray-900">{this.props.destination} Price — {getSymbolFromCurrency(this.props.currency)} {this.props.destinationPrice}</p>
      <p className={`mt-1 text-sm font-semibold ${costTypeClass}`}>{suggestionText}</p>
    </div>
      // <div
      //   className="relative product-card brand-cards rounded bg-white shadow-sm hover:shadow transition duration-500 ease-in-out cursor-pointer"
      //   onClick={onCardClick}
      // >
      //   <img
      //     className="center card-img-top thumbnail w-full h-56 object-cover rounded-t md:h-auto"
      //     src={this.props.image}
      //     alt={this.props.name}
      //   />
      //   <span
      //     className={`absolute percentage-off top-0 right-0 text-white mt-2 mr-2 rounded-full py-1 px-2 text-xs font-medium leading-none ${costTypeClass}`}
      //   >
      //     {Math.abs(this.props.percentageDiff)} %
      //   </span>
      //   <div className="card-body p-2 md:p-3 border-t border-gray-200 text-center">
      //     <h5 className="card-title text-md leading-tight truncate" style={{textTransform: "capitalize"}}>
      //       {this.props.name}
      //     </h5>
      //     <p className="product-prices text-sm mt-1">
      //       <span
      //         className={`mr-2 ${
      //           this.props.percentageDiff < 0
      //             ? "crossed line-through text-gray-500"
      //             : "font-bold"
      //         }`}
      //       >
      //         <NumberFormat
      //           value={this.props.sourcePrice}
      //           displayType={"text"}
      //           thousandSeparator={true}
      //           prefix={getSymbolFromCurrency(this.props.currency)}
      //         />
      //       </span>
      //       <span
      //         className={
      //           this.props.percentageDiff < 0
      //             ? "font-bold"
      //             : "crossed line-through text-gray-500"
      //         }
      //       >
      //         <NumberFormat
      //           value={this.props.destinationPrice}
      //           displayType={"text"}
      //           thousandSeparator={true}
      //           prefix={getSymbolFromCurrency(this.props.currency)}
      //         />
      //       </span>
      //     </p>
      //     <p
      //       className={`absolute-savings text-sm mt-1 ${costTypeClass.replace(
      //         "bg",
      //         "text"
      //       )}`}
      //     >
      //       {suggestionText}{" "}
      //       <NumberFormat
      //         value={Math.abs(
      //           this.props.destinationPrice - this.props.sourcePrice
      //         )}
      //         displayType={"text"}
      //         thousandSeparator={true}
      //         prefix={getSymbolFromCurrency(this.props.currency)}
      //       />
      //       <span className="block sm:inline">
      //         {" "}
      //         in{" "}
      //         <ReactCountryFlag
      //           countryCode={this.props.destination}
      //           svg
      //           style={{
      //             width: "1.25em",
      //             height: "1.25em",
      //             marginLeft: "0.25rem",
      //             borderRadius: "4px",
      //           }}
      //         />
      //       </span>
      //     </p>
      //   </div>
      // </div>
    );
  }
}

ProductCard.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  percentageDiff: PropTypes.number,
  name: PropTypes.string,
  sourcePrice: PropTypes.number,
  destinationPrice: PropTypes.number,
  currency: PropTypes.string,
  sourceUrl: PropTypes.string,
  destinationUrl: PropTypes.string,
  destination: PropTypes.string,
  source: PropTypes.string,
  onCardClick: PropTypes.func
};

export default ProductCard;
