import React from "react";

export default function Newsletter() {
  return (
    <div>
      <div className="container mx-auto px-4 py-12 lg:py-16 xl:max-w-screen-lg ">
        <div className="px-6 py-6 bg-gray-900 rounded-xl md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
              Want products news and updates?
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-200">
              Sign up for our newsletter to stay up to date.
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <form className="sm:flex">
              <input
                aria-label="Email address"
                type="email"
                required
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:bg-gray-400 transition duration-150 ease-in-out">
                  Notify me
                </button>
              </div>
            </form>
            <p className="mt-3 text-sm leading-5 text-gray-200">
              We won't spam or sell your email.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
