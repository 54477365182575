import React from "react";
import ReactDOM from "react-dom";
// import "./index.scss";
import "./styles/main.css";
import { Dashboard, BrandPage } from "./containers";
import Footer from "./components/Footer";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { Route, Switch } from "react-router"; // react-router v4/v5
import { ConnectedRouter } from "connected-react-router";
import { store, browserHistory } from "./utilities";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={browserHistory}>
      <>
        <title>Travelshoppr.com</title>
        <main
          className="antialiased"
          style={{
            background: "linear-gradient(180deg, #FFFFFF 0%, #F3E9EE 29.69%)",
          }}
        >
          <div className="container mx-auto md:px-4 lg:px-8 xl:max-w-screen-lg xl:px-0 lg:pt-12">
            <Switch>
              <Route exact path="/" component={Dashboard}></Route>
              <Route exact path="/brand" component={BrandPage}></Route>
            </Switch>
          </div>
          <Footer />
        </main>
      </>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
