import React from "react";
import PropTypes from "prop-types";

class Dropdown extends React.Component {
  onSelect(event, onItemSelect) {
    onItemSelect(event.target.value);
  }

  render() {
    var items = this.props.items.filter(
      (item) => !(this.props.removeItems || []).includes(item.value)
    );
    return (
      <div className="country-dropdown relative inline-block">
        <select
          className="block w-full pl-3 pr-10 py-2 text-lg border border-purple-200 focus:outline-none focus:ring-purple-500 focus:border-purple-500 rounded-md text-primary"
          value={this.props.selectedItem}
          onChange={(event) => this.onSelect(event, this.props.onItemSelect)}
        >
          {items.filter((x) => x.value).map((item) => {
            return (
              <option key={`${this.props.type}_${item.value}`} value={item.value}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

Dropdown.propTypes = {
  type: PropTypes.string,
  selectedItem: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  removeItems: PropTypes.arrayOf(PropTypes.string),
};

export default Dropdown;
