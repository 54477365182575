function convertToQueryParameterString(keyValueMap) {
    return Object.keys(keyValueMap).map((key) => `${key}=${keyValueMap[key]}`).join("&");
}

function getCurrentQueryParameters() {
    return new URLSearchParams(window.location.search);
}

function fetchMetadata(source, destination, currency, onSuccessCallback, onErrorCallback) {
    var queryParamString = convertToQueryParameterString({
        source: source,
        destination: destination,
        currency: currency
    });
    fetch(`${baseUrl()}v1/meta?${queryParamString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                if (onSuccessCallback) {
                    onSuccessCallback(result);
                }
            },
            (error) => {
                if (onErrorCallback) {
                    onErrorCallback(error);
                }
            }
        );
}

function baseUrl(action_name) {
    return `${process.env.REACT_APP_API_URL}/api/`;
}

export { convertToQueryParameterString, getCurrentQueryParameters, fetchMetadata, baseUrl };