import React from "react";
import PropTypes from "prop-types";

class OptionPicker extends React.Component {
  render() {
    return (
      <div className="option-picker mt-4 p-4 container mx-auto">
        {this.props.options.map((option) => {
          return (
            <button
              className={`option-item border border-purple-200 py-1 px-3 rounded-md mr-2 mb-2 text-regular text-purple-800 hover:border-purple-400 transition duration-500 ease-in-out bg-white ${
                option.value === this.props.selected
                  ? "font-semibold border-purple-400"
                  : ""
              }`}
              key={option.value}
              onClick={() => this.props.onSelect(option.value)}
            >
              {option.name}
            </button>
          );
        })}
      </div>
    );
  }
}

OptionPicker.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

export default OptionPicker;
