import React from "react";
import PropTypes from "prop-types";

class BrowseBrandCard extends React.Component {
  render() {
    return (
      <div
        className="product-card brand-cards bg-white rounded shadow-sm p-4 text-center flex items-center justify-center hover:shadow transition duration-500 ease-in-out cursor-pointer"
        onClick={() => {
          this.props.onCardClick(this.props.id);
        }}
      >
        <div className="flex items-center flex-col">
          <div className="bg-purple-100 rounded-full h-12 w-12 flex items-center justify-center mb-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 12H3M14 5L21 12L14 5ZM21 12L14 19L21 12Z"
                stroke="#805ad5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          Browse all products from {this.props.name}
        </div>
      </div>
    );
  }
}

BrowseBrandCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onCardClick: PropTypes.func,
};

export default BrowseBrandCard;
